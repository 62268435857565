import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const WHOperatorIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M5.4,17.92h13.29M5.4,13.49h13.29M23.12,7.23v12.9c0,.59-.23,1.15-.65,1.56-.42.42-.97.65-1.56.65H3.18c-.59,0-1.15-.23-1.56-.65-.42-.42-.65-.97-.65-1.56V7.23c0-.44.13-.87.39-1.24.24-.37.6-.64,1.01-.81L11.23,1.63c.53-.21,1.11-.21,1.64,0l8.86,3.54c.41.17.76.44,1.01.81s.39.8.39,1.24ZM5.4,9.05h13.29v13.29H5.4v-13.29Z"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
))
