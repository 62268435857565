import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const FruitTruckDriverIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M3.02,17.44H.81v-6.02l3.26-5.8h5.97l.06,5.8h13.1v6.02h-2.27M8.1,17.44h7.79M3.13,17.44c0,1.33,1.11,2.43,2.43,2.43s2.43-1.11,2.43-2.43-1.11-2.43-2.43-2.43-2.43,1.05-2.43,2.43ZM15.9,17.44c0,1.33,1.11,2.43,2.43,2.43s2.43-1.11,2.43-2.43-1.11-2.43-2.43-2.43-2.43,1.05-2.43,2.43ZM21.31,11.41c.11-1.22-.22-2.49-1.11-3.43-1.66-1.71-4.2-1.6-6.08-.17-.83.61-1.55,2.04-1.38,3.59M16.12,8.26c-.88-2.71-2.93-2.43-2.93-2.43M18.6,3.29s-3.43-.22-3.76,3.09c0-.06,3.15.88,3.76-3.09ZM10.09,17.16v-5.75"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
))
